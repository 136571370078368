/* eslint-disable */
import fabricInstanceMixin from './fabricInstanceMixin'

//吸附的最小宽度
const MIN_ADSORPTION_NUM = 15
export default {
  mixins: [fabricInstanceMixin],

  data() {
    return {
      showPosLine: false,
      pointer: {
        x: 0,
        y: 0
      }
    }
  },

  //事件
  methods: {
    //选择创建
    selectionCreated(option) {
      this.updateAngle(option)
      this.updateActiveObject(option)
      this.updateActiveObjectScale(option)
    },

    //选择取消
    selectionCleared(option) {
      this.updateAngle(option)
      this.updateActiveObject({
        target: null
      })
      this.updateActiveObjectScale({ target: null })
    },

    //选择更新
    selectionUpdated(option) {
      this.updateAngle(option)
      this.updateActiveObject(option)
      this.updateActiveObjectScale(option)
    },

    //鼠标按下
    mouseDown(e) {
      this.pointer = e.pointer
      this.pointer.existActiveObject = !!this.getActiveObject()
    },

    //鼠标向上
    mouseUp(e) {
      //去除不展示的图层
      this.hiddenNotShowLayer()
      //位置不变，不重新渲染
      // if (!this.needToRender(this.pointer, e.pointer)) {
      //   return
      // }
      //吸附作用
      this.adsorption()
      //隐藏中心线
      this.toggleShowPosLine(false)

      this.render()
    },

    //活动对象移动中
    objectMoving(option) {
      this.toggleShowPosLine(true)
    },

    //活动对象旋转中
    objectRotating(option) {
      const {
        target: { angle }
      } = option
      const objects = this.canvas.getObjects()
      const textboxArr = objects.filter(({ type }) => {
        return type === 'textbox'
      })

      textboxArr[0] &&
        textboxArr[0].setOptions({
          text: parseInt(angle) + '°'
        })
    },

    //活动对象旋转
    objectRotated(option) {
      this.updateAngle(option)
      this.updateActiveObject(option)
    },

    //活动对象缩放
    objectScaled(option) {
      this.updateActiveObjectScale(option)
      this.updateActiveObject(option)
    },

    //渲染事件
    afterRender(objects) {
      this.SET_FABRIC_OBJECTS({ objects })
    },

    //隐藏不显示图层
    hiddenNotShowLayer() {
      const willMoveObjs = this.canvas.getObjects().filter((item) => item.type !== 'group')
      willMoveObjs.map((o) => {
        this.instance.canvas.remove(o)
      })
    },

    //切换中心线
    toggleShowPosLine(bool) {
      this.showPosLine = bool
    },

    //吸附作用
    adsorption() {
      const { abs } = Math
      if (this.showPosLine) {
        const activeObject = this.getActiveObject()
        const { left: cL, top: cT } = this.instance.canvas.getCenter()
        const { top, right, bottom, left, width: oWidth, height: oHeight } = this.getPos()
        if (abs(left - cL) <= MIN_ADSORPTION_NUM) {
          activeObject.setOptions({
            left: cL + oWidth / 2
          })
        }

        if (abs(right - cL) <= MIN_ADSORPTION_NUM) {
          activeObject.setOptions({
            left: cL - oWidth / 2
          })
        }

        if (abs(top - cT) <= MIN_ADSORPTION_NUM) {
          activeObject.setOptions({
            top: cT + oHeight / 2
          })
        }
        if (abs(bottom - cT) <= MIN_ADSORPTION_NUM) {
          activeObject.setOptions({
            top: cT - oHeight / 2
          })
        }
      }
    },

    //获取当前对象位置
    getPos() {
      const activeObject = this.getActiveObject()
      const { left, top, width, height } = activeObject.getBoundingRect(true, true)
      return {
        top,
        right: left + width,
        bottom: top + height,
        left,
        width,
        height
      }
    },

    //更新活动对象旋转角度
    updateAngle(option) {
      let { target } = option
      target = target || { angle: 0 }
      this.UPDATE_CUR_FABRIC_ACTIVE_OBJECT_ANGLE(target.angle)
    },

    //更新活动对象缩放
    updateActiveObjectScale(option) {
      const { target } = option
      const scale = target
        ? {
            scaleX: target.scaleX,
            scaleY: target.scaleY
          }
        : null
      this.UPDATE_CUR_FABRIC_ACTIVE_OBJECT_SCALE(scale)
    },

    //更新活动对象
    updateActiveObject(option) {
      const { target } = option
      this.UPDATE_CUR_FABRIC_ACTIVE_OBJECT(target)
    }
  }
}
