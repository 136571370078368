export default {
  mounted() {
    this.bindKeyFunc()
  },

  methods: {
    bindKeyFunc() {
      //键盘 上下左右 事件
      document.addEventListener('keydown', this.actionFun)
    },

    actionFun(e) {
      const actionObj = {
        ['37']: {
          prop: 'left',
          step: -1
        },
        ['38']: {
          prop: 'top',
          step: -1
        },
        ['39']: {
          prop: 'left',
          step: 1
        },
        ['40']: {
          prop: 'top',
          step: 1
        }
      }
      const activeObject = this.getActiveObject(),
        action = actionObj[e.keyCode]
      if (action && activeObject) {
        activeObject.setOptions({
          [`${action.prop}`]: activeObject[`${action.prop}`] + action.step
        })
        activeObject.setCoords()
        this.instance.renderAll()
        this.canvasModifiedHandlerTimer && clearTimeout(this.canvasModifiedHandlerTimer)
        this.canvasModifiedHandlerTimer = setTimeout(() => {
          this.canvasModifiedHandler()
        }, 200)
      }
    }
  },
  destroyed() {
    document.removeEventListener('keydown', this.actionFun)
  }
}
