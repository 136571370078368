<template>
  <div class="design-chunk">
    <div class="title">基础组件</div>
    <div
      @click="chunkClickHandler(item)"
      :key="index"
      :class="['common-chunk', ...item.className]"
      v-for="(item, index) in chunkList"
    ></div>
  </div>
</template>

<script>
import { fabricInstanceMixin } from '../../../mixins'
import { PIC_INIT_W, PIC_INIT_H } from '@/utils/constant'
export default {
  mixins: [fabricInstanceMixin],

  data() {
    return {
      chunkList: [
        {
          id: 1,
          width: PIC_INIT_W,
          height: PIC_INIT_H,
          scaleX: 0.5,
          scaleY: 0.5,
          className: ['chunk-1']
        },
        {
          id: 2,
          width: PIC_INIT_W,
          height: PIC_INIT_H,
          scaleX: 0.5,
          scaleY: 0.25,
          className: ['chunk-2']
        }
      ]
    }
  },

  created() {
    this.initEditDesignData()
  },

  methods: {
    chunkClickHandler({ id, width, height, scaleY = 1, scaleX = 1 }) {
      this.createRectGroup({ id, width, height, scaleY, scaleX })
      this.canvasModifiedHandler()
    },

    initEditDesignData() {
      if (!this.isEdit) return
      const { customLayoutBlockList = [] } = this.customFloorData || {}
      const os = this.formatToCanvasObjects(customLayoutBlockList)
      os.map((item) => {
        this.createRectGroup(item)
      })
      this.canvasModifiedHandler()
    },

    formatToCanvasObjects(objects) {
      return objects.map((item) => {
        //定制画布相对原始画布尺寸
        const { width, height, left, top, angle, id, scaleX, scaleY } = this.getOriginToDesignObject(item)
        return { width, height, left, top, angle, scaleX, scaleY, id }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 20px;
}

.common-chunk {
  margin-right: 5%;
  display: inline-block;
  height: 80px;
  border: 2px solid $border-color;
}
.chunk-1 {
  width: 80px;
}
.chunk-2 {
  width: 160px;
}
</style>