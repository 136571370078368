import { mapGetters, mapMutations } from 'vuex'
import {
  ORIGIN_SHOW_AREA_W,
  ORIGIN_SHOW_AREA_H,
  DESIGN_SHOW_AREA_W,
  DESIGN_SHOW_AREA_H,
  DESIGN_AREA_W,
  DESIGN_AREA_H,
  RECT_LINE_WIDTH,
  FILL_COLOR
} from '@/utils/constant'

import { getUUID, createRandomNum } from '@/utils'

export default {
  // inject: ['canvasModifiedHandler', 'isEdit'],
  inject: {
    canvasModifiedHandler: { default: () => ({}) },
    isEdit: { default: () => false }
  },
  computed: {
    ...mapGetters([
      'customFloorData', //编辑时候获取定制数据
      'customDesignInstance', //实例
      'fabricObjects',
      'customFloorRenderFinish',
      'curFabircActiveObject', //当前激活对象
      'curFabircActiveObjectAngle',
      'curFabircActiveObjectScale'
    ]),
    //重新命名实例名称
    instance() {
      return this.customDesignInstance
    },

    canvas() {
      return this.customDesignInstance
    }
  },

  methods: {
    ...mapMutations([
      'SET_CUSTOM_FLOOR_DATA', //设置编辑初始数据
      'SET_FABRIC_OBJECTS',
      'SET_CUSTOM_FLOOR_RENDER_FINISH', //画布渲染状态
      'UPDATE_CUR_FABRIC_ACTIVE_OBJECT', //更新对象
      'UPDATE_CUR_FABRIC_ACTIVE_OBJECT_ANGLE', //更新角度
      'UPDATE_CUR_FABRIC_ACTIVE_OBJECT_SCALE', //更新缩放
      'CLEAR_DESIGN_STATE' //清除design 文件的state
    ]),

    getActiveObject() {
      return this.instance && this.instance.getActiveObject()
    },

    getActiveObjectType() {
      const activeObject = this.getActiveObject()
      if (!activeObject) return false
      return activeObject.type
    },

    //获取clip参数
    getCanvasRotateClipPos({ rW, rH, cW, cH, angle }) {
      const { t, r, b, l, w, h } = this.getClipPos({ rW, rH, cW, cH })
      return this.getRectPosByAngle(
        { left: l + w / 2, top: t + h / 2, width: w, height: h, scaleX: 1, scaleY: 1, angle },
        angle,
        {
          x: DESIGN_SHOW_AREA_W / 2,
          y: DESIGN_SHOW_AREA_H / 2
        }
      )
    },

    //获取图片在maker上的缩放比率
    getOriginScaleInMaker(activeObject, info) {
      if (!activeObject || !info || !info.width || !info.height) {
        return {
          scaleX: 1,
          scaleY: 1
        }
      }
      const { width, height } = activeObject
      const [oRand, oWidth, oHeight, oId] = activeObject.id.split('@')
      const { width: mW, height: mH } = info
      const knifeMarkerSize = Math.max(mW, mH)
      const picWdidth = oWidth * (DESIGN_AREA_W / knifeMarkerSize)
      const picHeight = oHeight * (DESIGN_AREA_H / knifeMarkerSize)
      return {
        oScaleX: picWdidth / width,
        oScaleY: picHeight / height
      }
    },

    //获取clip参数
    getClipPos({ rW, rH, cW, cH }) {
      if (rW === undefined) {
        rW = cW
      }
      if (rH === undefined) {
        rH = cH
      }
      // const cW = DESIGN_SHOW_AREA_W;
      // const cH = DESIGN_SHOW_AREA_H;
      const t = (cH - rH) / 2
      const r = l + w
      const b = t + h
      const l = (cW - rW) / 2
      const w = cW - l * 2
      const h = cH - t * 2
      return {
        t,
        r,
        b,
        l,
        w,
        h
      }
    },

    //获取当前选中图层的4个点的位置
    getRectPos(activeObject) {
      // const { left, top, height, width } = activeObject.getBoundingRect(true)
      const { left: oL, top: oT, width: oW, height: oH, scaleX: oScaleX, scaleY: oScaleY, angle } = activeObject
      const left = oL - (oW * oScaleX) / 2
      const top = oT - (oH * oScaleY) / 2
      const right = left + oW * oScaleX
      const bottom = top + oH * oScaleY

      return [
        { x: left, y: top },
        { x: right, y: top },
        { x: right, y: bottom },
        { x: left, y: bottom }
      ]
    },

    //获取旋转之后的位置
    getRectPosByAngle(activeObject, angle, originPoint) {
      originPoint = originPoint || activeObject.getCenterPoint()
      angle = angle || activeObject.angle || 0
      const posList = this.getRectPos(activeObject)
      return posList.map((pos) => {
        const degreesToRadiansAngle = fabric.util.degreesToRadians(angle)
        return fabric.util.rotatePoint(pos, originPoint, degreesToRadiansAngle)
      })
    },

    //根据旋转角度获取旋转之后的位置
    getRotatePosByAngle(point, originPoint, angle) {
      const degreesToRadiansAngle = fabric.util.degreesToRadians(angle)
      return fabric.util.rotatePoint(point, originPoint, degreesToRadiansAngle)
    },

    /**
     * @description: 对比原始尺寸的缩放比率
     * @param {*}
     * @return {*}
     */
    getCanvasScaleToOrigin() {
      const [scaleX, scaleY] = [ORIGIN_SHOW_AREA_W / DESIGN_SHOW_AREA_W, ORIGIN_SHOW_AREA_H / DESIGN_SHOW_AREA_H]
      return [scaleX, scaleY]
    },

    /**
     * @description: 转换画布块对象相对于原始画布的块信息
     * @param {*}
     * @return {*}
     */
    getObjectToOrigin(object) {
      const { width, height, left, top, angle, scaleX, scaleY, id } = object
      const [canvasScaleX, canvasScaleY] = this.getCanvasScaleToOrigin()
      return {
        width: width * canvasScaleX,
        height: height * canvasScaleY,
        left: left * canvasScaleX,
        top: top * canvasScaleY,
        angle,
        scaleX,
        scaleY,
        id
      }
    },

    /**
     * @description: 转换元素画布块对象相对于定制画布的块信息
     * @param {*}
     * @return {*}
     */
    getOriginToDesignObject(object) {
      let { width, height, centerX, centerY, rotation, id, scaleX, scaleY } = object
      const [canvasScaleX, canvasScaleY] = this.getCanvasScaleToOrigin()
      return {
        width: width / canvasScaleX,
        height: height / canvasScaleY,
        left: centerX / canvasScaleX,
        top: centerY / canvasScaleY,
        angle: rotation,
        scaleX,
        scaleY,
        id
      }
    },

    /**
     * @description: 根据缩放调整线宽，使线宽不变。
     * @param {*}
     * @return {*}
     */
    updateLineWidth(group) {
      const activeGroup = group || this.getActiveObject()
      if (!activeGroup) return
      const { scaleX, scaleY, width, height } = activeGroup
      const [t, r, b, l] = activeGroup.getObjects()
      const lineWidth = RECT_LINE_WIDTH

      t.setOptions({
        height: lineWidth / scaleY
      })
      r.setOptions({
        width: lineWidth / scaleX,
        left: width / 2 - lineWidth / scaleX
      })

      b.setOptions({
        height: lineWidth / scaleY,
        top: height / 2 - lineWidth / scaleY
      })

      l.setOptions({
        width: lineWidth / scaleX
      })
    },

    //使用组代替rect，解决线条缩放线条跟随缩放问题。
    createRectGroup({ id, width, height, left, top, angle = 0, scaleY = 1, scaleX = 1 }) {
      left = left || width / 2
      top = top || height / 2
      const lineWidth = RECT_LINE_WIDTH
      const fill = FILL_COLOR
      const tRect = new fabric.Rect({
        width,
        height: lineWidth,
        fill,
        left,
        top
      })

      const rRect = new fabric.Rect({
        width: lineWidth,
        height: height - lineWidth,
        fill,
        left: left + width - lineWidth,
        top
      })

      const bRect = new fabric.Rect({
        width,
        height: lineWidth,
        fill,
        left,
        top: top + height - lineWidth * 2
      })

      const lRect = new fabric.Rect({
        width: lineWidth,
        height: height - lineWidth,
        fill,
        left,
        top
      })

      const group = new fabric.Group([tRect, rRect, bRect, lRect], {
        id: `${createRandomNum()}@${width}@${height}@_${id}`,
        width,
        height,
        scaleY,
        scaleX,
        left,
        top,
        angle,
        originX: 'center',
        originY: 'center'
      })
      this.updateLineWidth(group)
      this.instance.canvas.add(group)
      this.instance.canvas.renderAll()
      return group
    },

    //创建一个画布
    createFabricCanvas() {
      const canvas = new fabric.Canvas(getUUID() + '', {
        preserveObjectStacking: true,
        width: DESIGN_SHOW_AREA_W,
        height: DESIGN_SHOW_AREA_H
      })
      return canvas
    },

    //渲染
    render() {
      this.canvasModifiedHandler()
    }
  }
}
